import React from 'react'

const Sales = () => {
  return (
    <>
      <h1 className='text-4xl font-extrabold uppercase'>Sales</h1>
      <p className='font-medium uppercase text-2xl'>This is this weeks offers!</p>
    </>
  )
}

export default Sales