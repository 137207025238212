import React, { useState } from 'react';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";


const TrackingModal = ({ isOpen, onClose, trackingID, setTrackingID, setTrackingInfo }) => {
  const [loading, setLoading] = useState(false);
  let url;
  if (process.env.NODE_ENV === "development") {
    url = 'http://localhost:2350/api/orders/tracking'
  } else {
    url = 'https://backend.creativeduo.net/api/orders/tracking'
  }

  const trackOrder = async () => {
    const apiToken = 'shippo_live_672ec38cdd85280c67681f17dff61d7f6afb35ec';
    const data = {
      carrier: 'usps' || 'dhl' || 'fedex' || 'ups',
      tracking_number: trackingID,
      metadata: 'Order 000123'
    };

    try {
      setLoading(true)
      const response = await axios.post(url, data, {
        headers: {
          'Authorization': `ShippoToken ${apiToken}`,
          'Content-Type': 'application/json'
        }
      });

      console.log("Response: ", response.data);  // Process the response as needed
      setTrackingInfo(response.data);
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setLoading(false);
    }

    onClose()
  }

  return (
    <div
      //onClick={onClose}
      className={`z-10 fixed w-full inset-0 flex justify-center items-center transition-colors
        ${isOpen ? "visible bg-black/80 dark:bg-white/50" : "invisible"}
      `}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white w-full md:w-1/2 dark:bg-black rounded-xl p-2 md:p-16 shadow transition-all 
          ${isOpen ? "scale-100 opacity-100" : "sclae-125 opacity-0"}
          `}
      >
        <button onClick={onClose}
          className='absolute top-2 right-2 p-1 px-2 rounded-lg text-red-700 bg-white hover:bg-gray-50
          hover:text-gray-600'
        >
          <p className="font-bold text-2xl p-4">X</p>
        </button>
        <p className="font-semibold pr-2 mb-2">TrackingID</p>
        <input type="text" placeholder='Enter Tracking ID...'
          value={trackingID}
          onChange={ev => setTrackingID(ev.target.value)}
          className="border-2 border-gray-300 rounded-md p-1 w-full 
          mb-2 focus:border-blue-900"
        />
        <button onClick={trackOrder} className="mt-2 p-2 bg-blue-600 text-white">
          {loading ? "Loading..." : "Track Order"}
        </button>
      </div>
    </div>
  );
};

export default TrackingModal;

// curl https://api.goshippo.com/tracks/ \
// -H "Authorization: ShippoToken shippo_live_672ec38cdd85280c67681f17dff61d7f6afb35ec" \
// -d carrier="usps" \
// -d tracking_number="9102969010383081813033" \
// -d metadata="Order 000123"

// curl https://api.goshippo.com/tracks/ \
// -H "Authorization: ShippoToken shippo_live_672ec38cdd85280c67681f17dff61d7f6afb35ec" \
// -d carrier="usps" \
// -d tracking_number="1ZG2C823YW04998601" \
// -d metadata="Order 000123"