/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { login, getGoogleUserInfo } from "../actions/userActions";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  useEffect(() => {
    if (!userInfo) {
      dispatch(getGoogleUserInfo());
    }
    // eslint-disable-next-line
  }, []);

  // const signInWithGoogleHandler = (e) => {
  //   const googleSignInEndPoint =
  //     process.env.NODE_ENV === "development"
  //       ? `http://localhost:2350/api/auth/google?redirect=${redirect}`
  //       : `/api/auth/google?redirect=${redirect}`;
  //   window.location.href = googleSignInEndPoint;
  // };
  const signInWithGoogleHandler = (e) => {
    const googleSignInEndPoint =
      process.env.NODE_ENV === "development"
        ? `http://localhost:2350/api/auth/google`  // no need for ?redirect here
        : `/api/auth/google`;  // make sure your domain is set up correctly

    window.location.href = googleSignInEndPoint;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div className="w-full">
      {/* className="login-screen" */}
      {/* <div className="login-screen-left">
        <img
          src="https://images.pexels.com/photos/3585089/pexels-photo-3585089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Login-Alt-Image"
        />
      </div> */}
      <div className="login-screen-right">
        <motion.div
          initial={{
            opacity: 0,
            y: 25,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 1.25,
            ease: "easeInOut",
          }}
          className="relative z-10 mx-auto w-full max-w-xl p-4"
        >
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Heading redirect={redirect} />

          <div>
            <div className="mb-3 flex gap-3">
              <button
                onClick={signInWithGoogleHandler}
                className=" bg-slate-200 rounded-full w-1/2 flex justify-center items-center p-1 hover:bg-slate-300"
              >
                <img
                  src="https://img.icons8.com/fluent/48/000000/google-logo.png"
                  alt=""
                  className="mr-2 h-10 w-10"
                />
                <p className="text-black">Sign In With Google</p>
              </button>
              <button
                onClick={signInWithGoogleHandler}
                className=" bg-slate-200 rounded-full w-1/2 flex justify-center items-center p-1 hover:bg-slate-300"
              >
                <img
                  src="https://img.icons8.com/fluent/48/000000/facebook.png"
                  alt=""
                  className="mr-2 fill-slate-400 h-10 w-10"
                />
                <p className="text-black">Sign In With Facebook</p>
              </button>
            </div>
            <BubbleButton className="flex w-full justify-center py-3">
              Passordless Sign In
            </BubbleButton>
          </div>

          <Or />
          <div>
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <label htmlFor="email-input" className="mb-1.5 block text-zinc-400 font-medium">
                  Email
                </label>
                <input
                  id="email-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="your.email@provider.com"
                  className="w-full rounded-md border border-zinc-700  bg-slate-300 px-3 py-2 placeholder-zinc-600 ring-1 ring-transparent transition-shadow focus:outline-0 focus:ring-blue-700 focus:bg-slate-100"
                />
              </div>
              <div className="mb-6">
                <div className="mb-1.5 flex items-end justify-between">
                  <label htmlFor="password-input" className="block text-zinc-400 font-medium">
                    Password
                  </label>
                  <Link
                    to="/forgot-password"
                    className="text-blue-500 hover:text-blue-800 text-medium"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </div>
                <input
                  id="password-input"
                  type="password"
                  placeholder="••••••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full rounded-md border border-zinc-700  bg-slate-300 px-3 py-2 placeholder-zinc-600 ring-1 ring-transparent transition-shadow focus:outline-0 focus:ring-blue-700 focus:bg-slate-100"
                />
              </div>
              <SplashButton type="submit" className="w-full">
                Sign in
              </SplashButton>
            </form>
          </div>
          <Terms />
        </motion.div>
        <CornerGrid />
      </div>
    </div>
  );
};

export default LoginScreen;


const Heading = (redirect) => (
  <div>
    <div className="mb-9 mt-6 space-y-1.5">
      <h1 className="text-2xl text-zinc-800 font-semibold">Sign in to your account</h1>
      <p className="text-zinc-400 font-medium text-[1.25em]">
        Don't have an account?{" "}
        <Link
          to={redirect ? `/register?redirect=${redirect}` : "/register"}
          className="text-blue-400 hover:text-blue-900"
        >
          Create One
        </Link>
      </p>
    </div>
  </div>
);

const Or = () => {
  return (
    <div className="my-6 flex items-center gap-3">
      <div className="h-[1px] w-full bg-zinc-700" />
      <span className="text-zinc-400">OR</span>
      <div className="h-[1px] w-full bg-zinc-700" />
    </div>
  );
};


const Terms = () => (
  <p className="mt-9 text-xs text-zinc-400">
    By signing in, you agree to our{" "}
    <a href="#" className="text-blue-400">
      Terms & Conditions
    </a>{" "}
    and{" "}
    <a href="#" className="text-blue-400">
      Privacy Policy.
    </a>
  </p>
);

const SplashButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-gradient-to-br from-blue-400 to-blue-700 px-4 py-2 text-lg text-zinc-50 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-zinc-950 transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] active:ring-blue-500/70",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const BubbleButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        `
      relative z-0 flex items-center gap-2 overflow-hidden whitespace-nowrap rounded-md 
      border border-zinc-700 bg-gradient-to-br from-zinc-800 to-zinc-950
      px-3 py-1.5
      text-zinc-50 transition-all duration-300
      
      before:absolute before:inset-0
      before:-z-10 before:translate-y-[200%]
      before:scale-[2.5]
      before:rounded-[100%] before:bg-zinc-100
      before:transition-transform before:duration-500
      before:content-[""]

      hover:scale-105 hover:text-zinc-900
      hover:before:translate-y-[0%]
      active:scale-100`,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const CornerGrid = () => {
  return (
    <div
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke-width='2' stroke='rgb(30 58 138 / 0.5)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
      }}
      className="absolute right-0 top-0 z-0 size-[50vw]"
    >
      <div
        style={{
          backgroundImage:
            "radial-gradient(100% 100% at 100% 0%, rgba(9,9,11,0), rgba(9,9,11,1))",
        }}
        className="absolute inset-0"
      />
    </div>
  );
};