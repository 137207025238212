import React from 'react';

const AddTrackingModal = ({isOpen, onClose, orderDispatchedHandler, trackingID, setTrackingID }) => {
  return (
    <div 
      //onClick={onClose}
      className={`z-10 fixed w-full inset-0 flex justify-center items-center transition-colors
        ${isOpen? "visible bg-black/80 dark:bg-white/50" : "invisible"}
      `}
    > 
      <div 
        onClick={(e) => e.stopPropagation()}
        className={`bg-white w-full md:w-1/2 dark:bg-black rounded-xl p-2 md:p-16 shadow transition-all 
          ${isOpen ? "scale-100 opacity-100": "sclae-125 opacity-0"}
          `}
      >
        <button onClick={onClose}
          className='absolute top-2 right-2 p-1 px-2 rounded-lg text-red-700 bg-white hover:bg-gray-50
          hover:text-gray-600'
        >
          <p className="font-bold text-2xl p-4">X</p>
        </button>
        <p className="font-semibold pr-2 mb-2">Add TrackingID</p>
        <input type="text" placeholder='Enter Tracking ID...' 
          value={trackingID} 
          onChange={ev => setTrackingID(ev.target.value)}
          className="border-2 border-gray-300 rounded-md p-1 w-full 
          mb-2 focus:border-blue-900" 
        /> 
        <button onClick={orderDispatchedHandler} className="mt-2 p-2 bg-blue-600 text-white">Add Tracking Number</button>
      </div>
    </div>
  );
};

export default AddTrackingModal;