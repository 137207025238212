export const ARTICLE_LIST_REQUEST = "ARTICLE_LIST_REQUEST";
export const ARTICLE_LIST_SUCCESS = "ARTICLE_LIST_SUCCESS";
export const ARTICLE_LIST_FAIL = "ARTICLE_LIST_FAIL";

export const ARTICLE_DETAILS_REQUEST = "ARTICLE_DETAILS_REQUEST";
export const ARTICLE_DETAILS_SUCCESS = "ARTICLE_DETAILS_SUCCESS";
export const ARTICLE_DETAILS_FAIL = "ARTICLE_DETAILS_FAIL";

export const ARTICLE_DELETE_REQUEST = "ARTICLE_DELETE_REQUEST";
export const ARTICLE_DELETE_SUCCESS = "ARTICLE_DELETE_SUCCESS";
export const ARTICLE_DELETE_FAIL = "ARTICLE_DELETE_FAIL";

export const ARTICLE_CREATE_REQUEST = "ARTICLE_CREATE_REQUEST";
export const ARTICLE_CREATE_SUCCESS = "ARTICLE_CREATE_SUCCESS";
export const ARTICLE_CREATE_FAIL = "ARTICLE_CREATE_FAIL";
export const ARTICLE_CREATE_RESET = "ARTICLE_CREATE_RESET";

export const ARTICLE_UPDATE_REQUEST = "ARTICLE_UPDATE_REQUEST";
export const ARTICLE_UPDATE_SUCCESS = "ARTICLE_UPDATE_SUCCESS";
export const ARTICLE_UPDATE_FAIL = "ARTICLE_UPDATE_FAIL";
export const ARTICLE_UPDATE_RESET = "ARTICLE_UPDATE_RESET";

export const ARTICLE_CREATE_REVIEW_REQUEST = "ARTICLE_CREATE_REVIEW_REQUEST";
export const ARTICLE_CREATE_REVIEW_SUCCESS = "ARTICLE_CREATE_REVIEW_SUCCESS";
export const ARTICLE_CREATE_REVIEW_FAIL = "ARTICLE_CREATE_REVIEW_FAIL";
export const ARTICLE_CREATE_REVIEW_RESET = "ARTICLE_CREATE_REVIEW_RESET";

export const ARTICLE_DELETE_REVIEW_REQUEST = "ARTICLE_DELETE_REVIEW_REQUEST";
export const ARTICLE_DELETE_REVIEW_SUCCESS = "ARTICLE_DELETE_REVIEW_SUCCESS";
export const ARTICLE_DELETE_REVIEW_FAIL = "ARTICLE_DELETE_REVIEW_FAIL";

export const ARTICLE_TOP_REQUEST = "ARTICLE_TOP_REQUEST";
export const ARTICLE_TOP_SUCCESS = "ARTICLE_TOP_SUCCESS";
export const ARTICLE_TOP_FAIL = "ARTICLE_TOP_FAIL";

export const ARTICLE_LATEST_REQUEST = "ARTICLE_LATEST_REQUEST";
export const ARTICLE_LATEST_SUCCESS = "ARTICLE_LATEST_SUCCESS";
export const ARTICLE_LATEST_FAIL = "ARTICLE_LATEST_FAIL";

export const ARTICLE_LIST_BY_CATEGORY_REQUEST =
  "ARTICLE_LIST_BY_CATEGORY_REQUEST";
export const ARTICLE_LIST_BY_CATEGORY_SUCCESS =
  "ARTICLE_LIST_BY_CATEGORY_SUCCESS";
export const ARTICLE_LIST_BY_CATEGORY_FAIL = "ARTICLE_LIST_BY_CATEGORY_FAIL";

export const ARTICLES_ORDER_BY_PRICE = "ARTICLES_ORDER_BY_PRICE";
