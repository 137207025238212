import React, { useState } from "react";

const ProductOptions = ({ setProductOptions, productOptions }) => {
  const [optionName, setOptionName] = useState("");
  const [optionPrice, setOptionPrice] = useState("");
  // console.log("Option Name",optionName, "Option Price: ", optionPrice)
  // console.log(onOptions, options, setProductOptions, productOptions )
  const optionHandler = (e) => {
    e.preventDefault();
    if (optionName && optionPrice) {
      setProductOptions([
        ...productOptions,
        { optionName: optionName, optionPrice: optionPrice },
      ]);
      setOptionName("");
      setOptionPrice("");
    }
  };
  // console.log("Product Options: ", productOptions);
  // delete Option
  const deleteOption = (e, option) => {
    e.preventDefault();
    setProductOptions(productOptions.filter((o) => o !== option));
  };

  return (
    <div>
      <h4>Product Options</h4>

      <div className="gap-1 px-3 py-2 my-1 border">
        {productOptions.length > 0 &&
          productOptions.map((option, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
              }}
            >
              {option.optionName} - ${option.optionPrice}
              <button
                type="button"
                className="px-2 my-1 text-white bg-red-500 rounded-md"
                onClick={(e) => deleteOption(e, option)}
              >
                Delete
              </button>
            </div>
          ))}
      </div>

      <div className="flex justify-between mb-2 action">
        <input
          type="text"
          placeholder="Option Name"
          value={optionName}
          onChange={(e) => setOptionName(e.target.value)}
          className="w-1/2 p-3 mr-1 rounded-md"
        />
        <input
          type="text"
          placeholder="Option Price"
          value={optionPrice}
          onChange={(e) => setOptionPrice(e.target.value)}
          className="w-1/2 p-3 rounded-md"
        />
        <br />
        <button onClick={optionHandler} className="p-3 ml-1 rounded-md bg-slate-200">
          Add Option
        </button>
      </div>
      {/* Map over the options which include name and price */}
      {/* {options.map((option, index) => (
        <div key={index} className="flex justify-between mb-2">
          <p>{option}</p>
          <button onClick={removeOption} className="p-3 bg-slate-300">
            Remove
          </button>
        </div>
      ))} */}
    </div>
  );
};

export default ProductOptions;
