import { useState, useEffect } from "react"

export default function useMobileDetection() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to check if the screen width is mobile-sized
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 968); // You can adjust the width as needed
        };

        // Initial check
        checkMobile();

        // Add event listener for window resize
        window.addEventListener('resize', checkMobile);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    return isMobile;
}
