export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'


export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST'
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS'
export const ORDER_LIST_MY_FAIL = 'ORDER_LIST_MY_FAIL'
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL'
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET'

// export const ORDER_SHIPMENTPAYMENT_REQUEST = 'ORDER_SHIPMENTPAYMENT_REQUEST'
// export const ORDER_SHIPMENTPAYMENT_SUCCESS = 'ORDER_SHIPMENTPAYMENT_SUCCESS'
// export const ORDER_SHIPMENTPAYMENT_FAIL = 'ORDER_SHIPMENTPAYMENT_FAIL'
// export const ORDER_SHIPMENTPAYMENT_RESET = 'ORDER_SHIPMENTPAYMENT_RESET'

export const ORDER_STATUS_REQUEST = 'ORDER_STATUS_REQUEST'
export const ORDER_STATUS_SUCCESS = 'ORDER_STATUS_SUCCESS'
export const ORDER_STATUS_FAIL = 'ORDER_STATUS_FAIL'
export const ORDER_STATUS_RESET = 'ORDER_STATUS_RESET'


export const ORDER_PACKED_REQUEST = "ORDER_PACKED_REQUEST";
export const ORDER_PACKED_SUCCESS = "ORDER_PACKED_SUCCESS";
export const ORDER_PACKED_FAIL = "ORDER_PACKED_FAIL";
export const ORDER_PACKED_RESET = "ORDER_PACKED_RESET";

export const ORDER_DISPATCHED_REQUEST = "ORDER_DISPATCHED_REQUEST";
export const ORDER_DISPATCHED_SUCCESS = "ORDER_DISPATCHED_SUCCESS";
export const ORDER_DISPATCHED_FAIL = "ORDER_DISPATCHED_FAIL";
export const ORDER_DISPATCHED_RESET = "ORDER_DISPATCHED_RESET";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";
export const ORDER_CANCEL_RESET = "ORDER_CANCEL_RESET";
