import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords, thumbnail }) => {
  return (
    <Helmet>
      <title>{title}</title>

      <meta property="og:url" content="https://creativeduo.net" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Creative Duo LLC" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={thumbnail} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Creative Duo LLC",
  description: "Custom Products and Services",
  keywords: "Custom Products, Custom Services, Creative Duo LLC",
  thumbnail:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHyQDyxX1voycT2p_7m3CKDfe8w7Yl5A18mg&s",
};

export default Meta;
