import React from 'react';
import { Link } from 'react-router-dom';

const categories = [
    {
        name: 'Lucky Merch',
        image: 'https://signwayonline.net/wp-content/uploads/2022/03/Gold-Trim-4-Leaf-Clover.jpg',
        link: "/category/Lucky"
    },
];

const CakeGrid = () => {
    return (
        <div className="flex flex-wrap gap-4 m-3 items-center justify-center">
            {categories.map((category, index) => (
                <Link to={category.link}>
                    <div key={index} className="flex flex-col items-center no-underline hover:no-underline">
                        <div
                            className="w-32 h-32 md:w-80 md:h-80 bg-cover bg-center mb-2 flex justify-center items-center rounded-lg no-underline hover:no-underline"
                            style={{ backgroundImage: ` url(${category.image})` }}
                        >
                            <div className="bg-black bg-opacity-40 hover:bg-opacity-60 hover:transition-all hover:ease-in-out duration-300 text-white font-bold text-xl text-center p-2 rounded w-32 h-32 md:w-80 md:h-80 flex justify-center items-center no-underline hover:no-underline">{category.name}</div>
                        </div>

                    </div>
                </Link>
            ))}
        </div>
    );
};

export default CakeGrid;
