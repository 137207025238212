import axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_VERIFICATION_LINK_FAIL,
  USER_VERIFICATION_LINK_REQUEST,
  USER_VERIFICATION_LINK_RESET,
  USER_VERIFICATION_LINK_SUCCESS,
  USER_FORGOT_PASSWORD_MAIL_REQUEST,
  USER_FORGOT_PASSWORD_MAIL_SUCCESS,
  USER_FORGOT_PASSWORD_MAIL_FAIL,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
} from "../constants/userConstants";
import { ORDER_LIST_MY_RESET } from "../constants/orderConstants";
// import Cookies from "cookies";

export const login = (email, password, phone) => async (dispatch) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "/api/users/login",
        { email, password, phone },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    }

    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "https://backend.creativeduo.net/api/users/login",
        { email, password, phone },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: "Invalid Email or Password"
        // error.response && error.response.data.message
          // ? error.response.data.message
          //: "Invalid Email or Password"// error.message,
    });
  }
};

export const logout = () => {
  return async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo.googleId) {
        if (process.env.NODE_ENV === "development") {
          await axios.get("/api/auth/logout");
        }
        if (process.env.NODE_ENV === "production") {
          await axios.get("https://backend.creativeduo.net/api/auth/logout");
        }
      }

      localStorage.clear();
      localStorage.removeItem("userInfo");
      localStorage.removeItem("cartItems");
      localStorage.removeItem("paymentMethod");
      localStorage.removeItem("orderNotes");
      dispatch({ type: USER_LOGOUT });
      dispatch({
        type: USER_REGISTER_RESET,
      });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_LIST_MY_RESET });
      dispatch({ type: USER_LIST_RESET });
      dispatch({
        type: USER_VERIFICATION_LINK_RESET,
      });
      document.location.href = "/login";
    } catch (err) {
      console.error(err);
    }
  };
};

export const getGoogleUserInfo = () => {
  return async (dispatch) => {
    try {
      if (process.env.NODE_ENV === "development") {
        dispatch({ type: USER_LOGIN_REQUEST });

        const { data } = await axios.get("/api/auth/currentuser");

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem("userInfo", JSON.stringify(data));
      }

      if (process.env.NODE_ENV === "production") {
        dispatch({ type: USER_LOGIN_REQUEST });

        const { data } = await axios.get(
          "https://backend.creativeduo.net/api/auth/currentuser"
        );

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem("userInfo", JSON.stringify(data));
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const verify = (name, email, password, phone) => async (dispatch) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_VERIFICATION_LINK_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/verificationlink",
        { name, email, password, phone },
        config
      );
      dispatch({
        type: USER_VERIFICATION_LINK_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_VERIFICATION_LINK_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "https://backend.creativeduo.net/api/users/verificationlink",
        { name, email, password, phone },
        config
      );
      dispatch({
        type: USER_VERIFICATION_LINK_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_VERIFICATION_LINK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const register = (token) => async (dispatch) => {
  try {
    if (process.env.NODE_ENV === "development ") {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post("/api/users", { token }, config);

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "https://backend.creativeduo.net/api/users",
        { token },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/users/${id}`, config);

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `https://backend.creativeduo.net/api/users/${id}`,
        config
      );

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_FORGOT_PASSWORD_MAIL_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        "/api/users/forgot-password",
        { email },
        config
      );

      dispatch({
        type: USER_FORGOT_PASSWORD_MAIL_SUCCESS,
        payload: data.message,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_FORGOT_PASSWORD_MAIL_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        "https://backend.creativeduo.net/api/users/forgot-password",
        { email },
        config
      );

      dispatch({
        type: USER_FORGOT_PASSWORD_MAIL_SUCCESS,
        payload: data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_MAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPassword = (password, token) => async (dispatch) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        "/api/users/reset-password",
        { newPass: password, resetLink: token },
        config
      );

      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data.message,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        "https://backend.creativeduo.net/api/users/reset-password",
        { newPass: password, resetLink: token },
        config
      );

      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(`/api/users/profile`, user, config);

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `https://backend.creativeduo.net/api/users/profile`,
        user,
        config
      );

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/users`, config);

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `https://backend.creativeduo.net/api/users`,
        config
      );

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`/api/users/${id}`, config);

      dispatch({ type: USER_DELETE_SUCCESS });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `https://backend.creativeduo.net/api/users/${id}`,
        config
      );

      dispatch({ type: USER_DELETE_SUCCESS });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(`/api/users/${user._id}`, user, config);

      dispatch({ type: USER_UPDATE_SUCCESS });

      dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

      dispatch({ type: USER_DETAILS_RESET });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `https://backend.creativeduo.net/api/users/${user._id}`,
        user,
        config
      );

      dispatch({ type: USER_UPDATE_SUCCESS });

      dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

      dispatch({ type: USER_DETAILS_RESET });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};
