import { useState } from "react";
import { motion } from "framer-motion";
import {
  SiGoogle,
  SiEtsy,
} from "react-icons/si";

const Testimonials = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="bg-white py-24 px-4 lg:px-8 grid items-center grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-4 overflow-hidden">
      <div className="p-4">
        <h3 className="text-5xl font-semibold">What our customers think</h3>
        <p className="text-slate-500 my-4">
          Our customers love us and we love them. We are thrilled to have such a great community of people who are passionate about our products.
        </p>
        <SelectBtns
          numTracks={testimonials.length}
          setSelected={setSelected}
          selected={selected}
        />
      </div>
      <Cards
        testimonials={testimonials}
        setSelected={setSelected}
        selected={selected}
      />
    </section>
  );
};

const SelectBtns = ({ numTracks, setSelected, selected }) => {
  return (
    <div className="flex gap-1 mt-8">
      {Array.from(Array(numTracks).keys()).map((n) => {
        return (
          <button
            key={n}
            onClick={() => setSelected(n)}
            className="h-1.5 w-full bg-slate-300 relative"
          >
            {selected === n ? (
              <motion.span
                className="absolute top-0 left-0 bottom-0 bg-slate-950"
                initial={{
                  width: "0%",
                }}
                animate={{
                  width: "100%",
                }}
                transition={{
                  duration: 5,
                }}
                onAnimationComplete={() => {
                  setSelected(selected === numTracks - 1 ? 0 : selected + 1);
                }}
              />
            ) : (
              <span
                className="absolute top-0 left-0 bottom-0 bg-slate-950"
                style={{
                  width: selected > n ? "100%" : "0%",
                }}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

const Cards = ({ testimonials, selected, setSelected }) => {
  return (
    <div className="p-4 relative h-[450px] lg:h-[500px] shadow-xl">
      {testimonials.map((t, i) => {
        return (
          <Card
            {...t}
            key={i}
            position={i}
            selected={selected}
            setSelected={setSelected}
          />
        );
      })}
    </div>
  );
};

const Card = ({
  Icon,
  description,
  name,
  position,
  selected,
  setSelected,
}) => {
  const scale = position <= selected ? 1 : 1 + 0.015 * (position - selected);
  const offset = position <= selected ? 0 : 95 + (position - selected) * 3;
  const background = position % 2 ? "black" : "white";
  const color = position % 2 ? "white" : "black";

  return (
    <motion.div
      initial={false}
      style={{
        zIndex: position,
        transformOrigin: "left bottom",
        background,
        color,
      }}
      animate={{
        x: `${offset}%`,
        scale,
      }}
      whileHover={{
        translateX: position === selected ? 0 : -3,
      }}
      transition={{
        duration: 0.25,
        ease: "easeOut",
      }}
      onClick={() => setSelected(position)}
      className="absolute top-0 left-0 w-full min-h-full p-8 lg:p-12 cursor-pointer flex flex-col justify-between"
    >
      <Icon className="text-7xl mx-auto" />
      <p className="text-lg lg:text-xl font-light italic my-8">
        "{description}"
      </p>
      <div>
        <span className="block font-semibold text-lg">{name}</span>
      </div>
    </motion.div>
  );
};

const testimonials = [
  {
    Icon: SiGoogle,
    description:
      "Excelente servicio, puntual a la hora de entrega, producto de muy buena calidad. English Translation: Excellent service, punctual delivery, very good quality product.",
    name: "Arlin Morel",
  },
  {
    Icon: SiEtsy,
    description:
      "Awesome and fast! Needed help and they got with me within minutes!! Great company great product. Marked them as a favorite!!!!",
    name: "Jeran",
  },
  {
    Icon: SiGoogle,
    description:
      "Muy agradecía por la excelente labor de Carlos en su negocio Creative Duo LLC por que siempre está dispuesto ayudarnos en lo que le pedimos que nos cree para nuestra familia❤🙏🙏 mucho éxito.... English Translation: I am very grateful for the excellent work of Carlos in his business Creative Duo LLC because he is always willing to help us in what we ask him to create for our family❤🙏🙏 much success....",
    name: "Ariadna Kowalski",
  },
  {
    Icon: SiGoogle,
    description:
      "I love creative duo ;) Creative duo has great customer service and they put much time , love , and dedication into all they do . I am very satasified with everything that I have ordered going from websites for my small buisness , to buisness cards , to shirts etc !",
    name: "Anna Sweet Treats",
  },
  {
    Icon: SiEtsy,
    description:
      "The quality of this mouse pad is INCREDIBLE. I love it and it looks amazing!!",
    name: "Trevor",
  },
  {
    Icon: SiEtsy,
    description:
      "Good quality good description met expectations",
    name: "Ricardo",
  },
];

export default Testimonials;